@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Inter";
}

.slick-dots
{
  bottom: -80px !important;
}

.slick-dots li button:before
{
  font-size: 14px !important;
  border: 1px solid white !important;
  border-radius: 50px !important;
  opacity: 100% !important;
  color: transparent !important;
}

.slick-dots li.slick-active button:before
{
  color: white !important;
  opacity: 100% !important;
}

